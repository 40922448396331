import React from "react";
import NotFound from "../components/NotFound";

export default function Settings() {
	return (
		<div>
			<NotFound msg="Settings Page Coming Soon" />
		</div>
	);
}
